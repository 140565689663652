var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-text',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$refs.headSearch)?_c('rs-table-title-bar',_vm._b({attrs:{"title":"Hatırlatıcılar","icon":"mdi-alarm-multiple","hide-edit":!_vm.can('edit-notification'),"edit-enabled":_vm.selectedItems.length === 1,"search":_vm.search.query},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"click:edit":_vm.handleEditClick,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)}}},'rs-table-title-bar',_vm.titleBarAttrs,false)):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('router-link',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"to":{
            name: 'communication.reminders.edit',
            params: { id: item.id },
          }}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.subject",fn:function({ value, item }){return [_c('router-link',{attrs:{"to":{
            name: 'communication.reminders.show',
            params: { id: item.id },
          }}},[_c('rs-table-cell-string',{attrs:{"value":value,"limit":30,"title":item.body}})],1)]}},{key:"item.reminded_at",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"show-time":"","value":value}})]}},{key:"item.is_closed",fn:function({ item, value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value,"title":item.closed_at || null}})]}},{key:"item.relation",fn:function({ item, value }){return [(item.company_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.companies.edit',
            params: { id: item.company_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.cluster_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.clusters.edit',
            params: { id: item.cluster_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.house_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.houses.edit',
            params: { id: item.house_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.resident_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.residents.edit',
            params: { id: item.resident_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.provider_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.cluster-providers.edit',
            params: { id: item.provider_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.safe_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.safes.edit',
            params: { id: item.safe_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.bank_account_id)?_c('router-link',{attrs:{"to":{
            name: 'definitions.bank-accounts.edit',
            params: { id: item.bank_account_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.invoice_id)?_c('router-link',{attrs:{"to":{
            name: 'expenses.invoices.edit',
            params: { id: item.invoice_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.fixture_id)?_c('router-link',{attrs:{"to":{
            name: 'other-definitions.fixtures.fixtures.edit',
            params: { id: item.fixture_id },
          }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.care_id)?[(item.care_type === 1)?_c('router-link',{attrs:{"to":{
              name: 'communication.breakdowns.edit',
              params: { id: item.care_id },
            }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.care_type === 2)?_c('router-link',{attrs:{"to":{
              name: 'communication.suggestions.edit',
              params: { id: item.care_id },
            }}},[_vm._v(" "+_vm._s(value)+" ")]):(item.care_type === 3)?_c('router-link',{attrs:{"to":{
              name: 'communication.complaints.edit',
              params: { id: item.care_id },
            }}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]:[_vm._v(" "+_vm._s(value)+" ")]]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }